import { render, staticRenderFns } from "./YoutubeCampaignEdit.vue?vue&type=template&id=13220035&scoped=true&"
import script from "./YoutubeCampaignEdit.vue?vue&type=script&lang=js&"
export * from "./YoutubeCampaignEdit.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&lang=css&"
import style1 from "./YoutubeCampaignEdit.vue?vue&type=style&index=1&id=13220035&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13220035",
  null
  
)

export default component.exports