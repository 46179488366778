<template>
  <b-modal
    id="upsert-campaign-date-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hidden="hidden"
    @shown="shown"
  >
    <div class="text-center">
      <h3 class="highlighted-color1 mb-4">
        <span>{{ $t('confirm') }}</span>
      </h3>
      <p>{{ $t('confirm_campaign_duration_update') }}</p>
    </div>
    <AlertWraper :content="content" :type="content_type" />
    <div class="mt-4 default-form">
      <Multiselect
        v-model="selectedPackages"
        :placeholder="$t('search2')"
        label="label"
        track-by="code"
        :options="packagesOptions"
        :multiple="true"
        @input="handleSelectAll"
      />
      <div class="d-flex gap-3 mt-3 justify-content-center">
        <Button class="btn btn-primary btn-sm" @click="confirm(true)">
          {{ $t('confirm') }}
        </Button>
        <Button class="btn btn-primary btn-sm" @click="confirm(false)">
          {{ $t('cancel') }}
        </Button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Multiselect from 'vue-multiselect'
import moment from 'moment'

export default {
  name: 'CampaignDateUpdateModal',
  props: ['packages', 'selected'],
  components: {
    AlertWraper,
    Button,
    Multiselect
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      selectedPackages: []
    }
  },
  watch: {
    selectedPackages (newVal) {
      if (newVal.includes('all')) {
        this.selectedPackages = this.packages.map(item => item.id)
      }
    }
  },
  computed: {
    /**
     * Get packages options
     */
    packagesOptions () {
      const allOption = { label: this.$t('select_all'), code: 'all' }
      const options = this.packages.map(item => ({
        label: `${item.name} (${item.start_date ? moment(item.start_date).format('DD/MM') : this.$t('not_defined')} - ${item.end_date ? moment(item.end_date).format('DD/MM') : this.$t('not_defined')})`,
        code: item.id
      }))
      return [allOption, ...options]
    }
  },
  methods: {
    /**
     * Confirm
    */
    confirm (response) {
      if (this.packages.length === 1 && response) {
        this.selectedPackages = [
          {
            label: this.packages[0].name,
            code: this.packages[0].id
          }
        ]
      }
      this.$emit('submit', this.selectedPackages)
    },
    /**
     * On modal hidden
     */
    hidden () {
      this.content = null
      this.selectedPackages = []
    },
    /**
     * Handle select all
    */
    handleSelectAll (selected) {
      if (selected.some(option => option.code === 'all')) {
        this.selectedPackages = this.packagesOptions.filter(
          option => option.code !== 'all'
        )
      }
    },
    /**
     * Shown
    */
    shown () {
      if (!this.selected) return
      this.selectedPackages = this.selected
    }
  }
}
</script>
