<template>
  <div>

    <!-- FORM HEADER -->
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="highlighted-color1">
          <span>{{ isEditPage ? $t('edit') : $t('create')}} {{ $t('campaign_on_meta') }}</span>
        </h1>
      </div>
      <div class="d-flex">
        <Button
          class="btn btn-color1 me-2"
          v-if="!isEditPage || (campaign && campaign.status === 'DRAFT')"
          :loading="isSavingDraft"
          @click="saveDraft"
          :disabled="!selectedAdvertiser"
        >
          {{ $t('save_draft') }}
        </Button>
        <Button
          class="btn btn-primary"
          v-b-modal.cancel-campaign-upsert-modal
        >
          {{ $t('cancel') }}
        </Button>
        <CancelCampaignUpsertModal
          :campaign="campaign"
          step="campaign"
        />
      </div>
    </div>
    <!-- END FORM HEADER -->

    <!-- BREADCRUMB -->
    <div class="mt-3">
      <div class="campaign-wrapper col-lg-8 mx-auto position-relative">
        <MetaCampaignNavigation
          :campaign="campaign"
          step="campaign"
          :innerStep="step"
          v-if="step !== 'init' || isEditPage"
        />
        <template v-if="!isEditPage || (isEditPage && campaign)">
        <!-- INIT STEP -->
          <template v-if="step === 'init'">
            <div class="">
              <div class="card">
                <div class="card-body">
                  <p>
                    {{ $t('easy_campaign_pixel_roads') }}
                  </p>
                  <p>
                    {{ $t('important_thoughts_for_campaign') }}
                  </p>
                  <ul>
                    <li>{{ $t('campaign_goal') }};</li>
                    <li>{{ $t('campaign_duration') }}</li>
                    <li>{{ $t('available_budget') }}*<br/>
                      <span class="font-size-xSmall">* {{ $t('daily_budget_calculation') }}</span>
                    </li>
                    <li>{{ $t('creatives_in_formats_300x250_300x600_320x450_320x480_336x280_728x90_jpeg_png') }}*<br/>
                      <span class="font-size-xSmall">*{{ $t('required') }}</span>
                    </li>
                    <li>{{ $t('more_about_formats.0') }} <a href="https://pixelroads.com.br/blog/transforme-suas-campanhas-dicas-para-criar-banners-que-convertem/" target="_blank" class="link-color3">{{ $t('more_about_formats.1') }}</a>;</li>
                    <li>{{ $t('target_audience_and_locations') }}</li>
                    <li>{{ $t('suggest_pixel_pre_installed') }}</li>
                  </ul>
                  <div class="mt-4 text-center">
                    <Button
                      class="btn btn-primary"
                      @click="changeStep('campaign')"
                    >
                      {{ $t('start2') }}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- END INIT STEP -->

          <!-- STEP HEADING -->
          <section class="my-4" v-if="step !== 'init'">
            <div class="text-center">
              <!-- STEP DESCRIPTION -->
              <p>
                {{ stepDescription }}
              </p>
              <div class="mb-3 text-start">
                <!-- BACK BUTTON -->
                <a href="#" class="link-color2" @click.prevent="changeStep(previousStep)" v-if="previousStep">
                  <i class="fa fa-angle-left me-2"></i>{{ $t('back') }}
                </a>
              </div>
            </div>
          </section>
          <!-- END STEP HEADING -->

          <!-- CAMPAIGN STEP -->
          <template>
            <section class="">
              <div class="mb-5">
                <div class="card">
                  <div class="card-body">
                    <form @submit.prevent="" class="default-form">
                      <MetaCampaignForm
                        v-if="step === 'campaign'"
                        v-model="form"
                        :advertisers="advertisers"
                        :campaign="campaign"
                        :form="form"
                        :is-saving-draft="isSavingDraft"
                        :step="step"
                        @update:step="handleStepChange"
                        @update:selectedCountries="handleCountriesChange"
                      ></MetaCampaignForm>
                    </form>
                  </div>
                </div>
              </div>
            </section>
            <UpsertAdvertiserModal
                @advertiser-created="getAdvertisers"
            />
          </template>
          <!-- END CAMPAIGN STEP -->

          <!-- ADSET STEP -->
          <MetaAdsetForm
          v-if="step === 'adset'"
          :is-saving-draft="isSavingDraft"
          :selected-advertiser="selectedAdvertiser"
          :form="form"
          :campaign="campaign"
          :step="step"
          @update:step="handleStepChange"
          >
          </MetaAdsetForm>

          <!-- ADCREATIVE STEP -->
          <MetaAdForm
            v-if="step === 'adcreative'"
            :campaign="campaign"
            :creative="creative"
            :form="form"
            :step="step"
            @update:step="handleStepChange"
            @submit="submit"
          >
          </MetaAdForm>
          <!-- END CREATIVE STEP -->

          <!-- CONCLUSION STEP -->
          <template v-if="step === 'created'">
            <section class="">
              <div class="pt-5 mb-5">
                <div class="card">
                  <div class=" card-body">
                    <!-- AD NAME -->
                    <div class="form-group mb-3 text-center">
                      <div v-if="creatingLoader">
                        <Loader
                          :size="'small'"
                          :align="'start'"
                        />
                      </div>
                      <div>
                        <p v-if="campaignCreated" class="m-auto form-label fw-bold">{{ $t('ad_created_successfully') }}</p>
                        <p v-else class="form-label fw-bold m-auto ">{{ $t('failed_to_create_ad') }}</p>
                      </div>
                    </div>
                    <!-- END AD NAME -->
                  </div>
                </div>
              </div>
            </section>
            <Button
              class="btn btn-primary"
              href="/dashboards"
            >
              {{ $t('list_ads') }}
            </Button>
            <!-- END ACTION -->
          </template>
          <!-- END CONCLUSION STEP -->
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import Button from '@/components/common/Button/Button.vue'
import CancelCampaignUpsertModal from '@/components/campaigns/CancelCampaignUpsertModal'
import CategoriesService from '@/modules/categories/services/categories-service'
import Loader from '@/components/common/Loader/Loader.vue'
import { mapState } from 'vuex'
import MetaCampaignNavigation from '@/components/campaigns/Meta/MetaCampaignNavigation.vue'
import moment from 'moment/moment'
import UpsertAdvertiserModal from '@/components/advertisers/UpsertAdvertiserModal.vue'
import CreativesService from '@/modules/creatives/services/creatives-service'
import MetaService from '@/modules/integrations/services/meta-integration-service'
import variables from '@/mixins/variables'
import HelpersService from '@/modules/helpers/services/helpers-service'
import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import MetaCampaignForm from './MetaCampaignForm.vue'
import MetaAdsetForm from './MetaAdsetForm.vue'
import MetaAdForm from './MetaAdForm.vue'

export default {
  mixins: [variables],
  name: 'MetaCampaignEdit2',
  props: ['baseCampaign'],
  components: {
    MetaCampaignNavigation,
    Button,
    CancelCampaignUpsertModal,
    UpsertAdvertiserModal,
    Loader,
    MetaCampaignForm,
    MetaAdsetForm,
    MetaAdForm
  },
  data () {
    return {
      advertisers: [],
      advertiser: null,
      advertisersLoader: false,
      creatingLoader: false,
      creativesLoader: false,
      autoSaveInterval: null,
      campaign: null,
      creatives: [],
      creative: null,
      isSavingDraft: false,
      campaignCreated: false,
      cities: [],
      citiesLoader: false,
      countries: [],
      countriesLoader: false,
      currentCreativeSelection: '',
      selectedCountries: [],
      selectedCreative: '',
      states: [],
      statesLoader: false,
      categories: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      step: 'init',
      form: {
        // todo hardcoded ate definir o cpc
        cpc_target_amount: 300,
        amount: 1,
        category_id: '',
        name: '',
        advertiser_id: '',
        countries: [],
        states: [],
        cities: [],
        start_date: '',
        end_date: '',
        browser: [],
        creatives: [],
        square_creatives: [],
        adCreativeName: '',
        adCreativeType: '',
        adCreativeUrl: '',
        adCreativeMessage: '',
        adsetName: '',
        adName: '',
        objective: '',
        special_ads_category: '',
        type: 'NATIVE',
        targeting: {
          age_max: 65,
          age_min: 18,
          genders: [
            1
          ],
          geo_locations: {
            countries: [],
            states: [],
            cities: []
          }
        },
        billingEvent: '',
        selectedAdsetId: '',
        adInitialStatus: '',
        platform: 'META',
        update: !!this.isEditPage,
        campaignId: null
      }
    }
  },
  watch: {
    user (newUser) {
      this.getPageContent()
    },
    /**
     * Watch for changes to countries
     */
    countries (newValue, oldValue) {
      // If is edit page, set selected countries
      if (this.isEditPage) this.setSelectedCountries()
    },
    /**
     * Watch for changes to selected countries
     */
    selectedCountries (newValue, oldValue) {
      this.getStates()
      this.getCities()
    },
    campaign () {
      this.form.campaignId = this.campaign.id
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    stepDescription () {
      switch (this.step) {
        case 'campaign':
          return this.$t('create_basic_campaign_settings')
        case 'adset':
          return this.$t('configure_the_ad_set_your_ad_will_be_linked_to')
        case 'adcreative':
          return this.$t('now_its_time_to_set_up_your_creative_and_ad_on_meta')
        default:
          return ''
      }
    },
    previousStep () {
      switch (this.step) {
        case 'campaign':
          return null
        case 'adset':
          return 'campaign'
        case 'adcreative':
          return 'adset'
        default:
          return null
      }
    },
    isEditPage () {
      return this.$route.name === 'campaigns.edit'
    },
    selectedAdvertiser () {
      if (!this.form.advertiser_id) return null

      return this.advertisers.find(item => {
        if (item.id === this.form.advertiser_id) {
          this.advertiser = item.id
        }
        return item.id === this.form.advertiser_id
      })
    },
    checkIfDailyBudgetIsBiggerThanMinimumDailyBudget () {
      if (this.suggestedend_date && this.form.amount > 0) {
        const startDate = moment(this.form.start_date)
        const endDate = moment(this.form.end_date)
        const campaignDays = endDate.diff(startDate, 'days') + 1
        const totalBudget = campaignDays * this.minimumDailyBudget

        return this.form.amount >= totalBudget
      }
      return true
    },
    isAdsetStepComplete () {
      if (
        !this.form.adsetName ||
        !this.form.targeting ||
        !this.form.billingEvent ||
        parseFloat(this.form.amount) <= 0 ||
        this.hasCampaignExceededAvailableAdvertiserAmount
      ) {
        return false
      }
      return true
    },
    isAdCreativeStepComplete () {
      return (
        (this.form.creatives.length > 0 ||
        this.form.square_creatives.length > 0) &&
        this.form.adCreativeType
      )
    },
    isAdStepComplete () {
      if (
        !this.form.adName ||
        !this.form.selectedAdsetId ||
        !this.form.adInitialStatus
      ) {
        return false
      }
      return true
    },
    hasCampaignExceededAvailableAdvertiserAmount () {
      if (!this.selectedAdvertiser) return true

      // If is new campaign, the only thing that matters is the full available amount
      const amount = this.form.amount * 100
      const available = this.selectedAdvertiser.available_amount

      if (!this.campaign) {
        return amount > available
      }

      // If is campaign that already exists, calculate the difference between new amount and old amount and compare that value to available amount
      const difference = amount - this.campaign.amount
      return difference > available
    },
    isCampaignDraft () {
      return this.campaign.status === 'DRAFT'
    },

    //  DATE METHODS
    isCampaignEndDateValid () {
      if (!this.form.end_date) return false
      const date = moment(this.form.end_date)
      // Check if is update and if value hasnt changed from original
      if (this.campaign && !this.isCampaignDraft) {
        const then = moment(this.campaign.end_date)
        if (date.isSame(then)) return true
      }
      const tomorrow = moment().startOf('day').add(1, 'day')
      return date.isSameOrAfter(tomorrow)
    },
    /**
     * Checks if campaign end date is before start date
     */
    isCampaignEndDateBeforeStartDate () {
      if (!this.form.end_date || !this.form.start_date) return true
      const startDate = moment(this.form.start_date)
      const endDate = moment(this.form.end_date)

      return endDate.isSameOrBefore(startDate)
    },
    /**
     * Check if campaign end date is before campaign packages end date
     */
    isCampaignEndDateBeforePackagesEndDate () {
      if (!this.campaign) return false
      let flag = false
      const endDate = moment(this.form.endDate)

      this.campaign.packages.forEach(campaignPackage => {
        const packageEndDate = moment(campaignPackage.endDate)
        if (endDate.isBefore(packageEndDate)) flag = true
      })

      return flag
    },
    /**
     * Check if campaign period is below recommended
     */
    isCampaignPeriodBelowRecommended () {
      if (!this.form.start_date || !this.form.end_date) return true
      const start = moment(this.form.start_date)
      const end = moment(this.form.end_date)
      return end.diff(start, 'days') < 7
    },
    /**
     * Is campaign start date valid
     */
    isCampaignStartDateValid () {
      if (!this.form.start_date) return false
      const date = moment(this.form.start_date)
      // Check if is update of started campaign and if value hasnt changed from original
      if (this.campaign && !this.isCampaignDraft) {
        const then = moment(this.campaign.start_date)
        if (date.isSame(then)) return true
      }
      const today = moment().endOf('day')
      return date.isAfter(today)
    },
    //  END DATE METHODS
    /**
     * Get countries options
     */
    // countryOptions () {
    //   return this.countries.map(item => {
    //     // this.form.targeting.geo_locations.countries.push(item.short_name)
    //     return {
    //       label: `${item.name} (${item.short_name})`,
    //       code: item.id,
    //       shortName: item.short_name
    //     }
    //   })
    // },
    /**
     * Get cities options based on the selected states of the line
     */
    // stateOptions () {
    //   return this.states.map(state => {
    //     return {
    //       label: `${state.name} (${state.country_short_name})`,
    //       code: state.id,
    //       name: state.name,
    //       shortName: state.short_name
    //     }
    //   })
    // },
    /**
     * Get cities options based on the selected states of the line
     */
    // citiesOptions () {
    //   const cities = this.cities.reduce((filtered, item) => {
    //     filtered.push({
    //       label: `${item.name} (${item.state_short_name} - ${item.country_short_name})`,
    //       code: item.id,
    //       shortName: item.name
    //     })
    //     return filtered
    //   }, [])
    //   return cities
    // },
    currentCreativeSelectionLayout () {
      switch (this.currentCreativeSelection) {
        case 'creative':
          return '1200x627'
        default:
          return ''
      }
    }
  },
  methods: {
    //  GET METHODS ON TOP
    /**
     * Get countries
     */
    async getCountries () {
      this.countriesLoader = true
      try {
        const countries = await HelpersService.getCountries()
        this.countries = countries
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.countriesLoader = false
    },
    updateSelectedCountries (newSelectedCountries) {
      this.selectedCountries = newSelectedCountries
    },
    /**
     * Get states
     */
    async getStates () {
      if (this.selectedCountries.length === 0) {
        this.states = []
        return
      }
      this.statesLoader = true
      try {
        const states = await HelpersService.getStates({
          country_id: this.selectedCountries.map(item => {
            return item.code
          })
        })
        this.states = states
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.statesLoader = false
    },
    /**
     * Get cities
     */
    async getCities () {
      if (this.selectedCountries.length === 0) {
        this.cities = []
        return
      }
      this.citiesLoader = true
      try {
        const cities = await HelpersService.getCities({
          countryId: this.selectedCountries.map(item => {
            return item.code
          })
        })
        this.cities = cities
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.citiesLoader = false
    },
    async createCampaign (form) {
      this.creatingLoader = true
      try {
        // todo resolver a identificação do act_id, se for da pixel, manter. Se não mudar
        const response = await MetaService.createCampaign(form, '604059311204647')
        console.log('metaservice', response)
      } catch (error) {
        console.log(error)
      }
    },
    handleStepChange (newStep) {
      this.step = newStep // Atualizando o valor de step no componente pai
    },
    handleCountriesChange (newCountries) {
      this.form.countries = newCountries // Atualizando o valor de step no componente pai
    },
    async getCampaign () {
      if (!this.baseCampaign) return
      this.campaign = this.baseCampaign
      this.setCampaign(this.baseCampaign)
    },
    async getAdvertisers () {
      this.advertisersLoader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          companyId: this.user.company.id,
          itemsPerPage: 100
        })
        this.advertisers = advertisers.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.advertisersLoader = false
    },
    async getCategories () {
      this.categoriesLoader = true
      try {
        const categories = await CategoriesService.getCategories({
          campaign_type: 'PROGRAMATIC',
          // todo incluir campaign_type META
          // campaign_type: 'META',
          type: 'CAMPAIGN'
        })
        this.categories = categories.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.categoriesLoader = false
    },
    async getCreatives () {
      try {
        const creatives = await CreativesService.getCreatives({
          advertiserId: this.form.advertiser_id
        })
        this.creatives = creatives.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
    },
    getPageContent () {
      this.getAdvertisers()
      this.getCategories()
      this.getCountries()
    },
    /**
     * Set selected countries
     */
    setSelectedCountries () {
      if (!this.campaign) return
      const countries = []
      this.campaign.states.forEach(item => {
        if (!countries.includes(item.country_short_name)) countries.push(item.country_short_name)
      })
      this.campaign.cities.forEach(item => {
        if (!countries.includes(item.country_short_name)) countries.push(item.country_short_name)
      })
      this.selectedCountries = this.countries.filter(item => {
        return countries.includes(item.short_name)
      }).map(item => {
        return {
          label: `${item.name} (${item.short_name})`,
          code: item.id,
          name: item.name,
          shortName: item.short_name
        }
      })
    },
    /**
     * Pushes selected locations into targeting object
     */
    setGeolocationTargeting () {
      this.selectedCountries.filter((country) => {
        this.form.targeting.geo_locations.countries.push(country.shortName)
      })

      this.form.states.filter((state) => {
        this.form.targeting.geo_locations.states.push(state.shortName)
      })

      this.form.cities.filter((city) => {
        this.form.targeting.geo_locations.cities.push(city.shortName)
      })
    },
    /**
     * Set Campaign
     * @param campaign
     */
    setCampaign (campaign) {
      this.form.advertiser_id = campaign.advertiser.id
      this.form.name = campaign.name
      // Check subcategory
      if (campaign.category.parent) {
        this.form.category_id = campaign.category.parent.id
        this.form.subcategory_id = campaign.category.id
      } else {
        this.form.category_id = campaign.category.id
      }
      this.form.start_date = campaign.start_date
      this.form.end_date = campaign.end_date
      this.form.amount = campaign.amount / 100
      this.form.pixels = campaign.pixels.map(item => {
        return {
          id: item.id,
          name: item.name,
          objective: item.objective,
          event_name: item.event_name,
          pixel: item.pixel
        }
      })
      this.form.cpm_target_amount = campaign.cpm_target_amount / 100
      this.form.cpc_target_amount = campaign.cpc_target_amount / 100
      this.form.browser = campaign.browser
      campaign.creatives.creatives.forEach(creative => {
        this.form.creatives.push(creative)
      })
      campaign.creatives.square_creatives.forEach(creative => {
        this.form.square_creatives.push(creative)
      })
      this.form.states = campaign.states.map(s => {
        return {
          label: s.name,
          code: s.id
        }
      })
      this.form.cities = campaign.cities.map(c => {
        return {
          label: `${c.name} (${c.state_short_name})`,
          code: c.id
        }
      })
      this.setSelectedCountries()
    },
    /**
     * Handle selected creatives
     */
    handleSelectedCreatives (creatives) {
      let array = []
      if (this.currentCreativeSelection === 'creative') array = this.form.creatives
      creatives.forEach(creative => {
        const index = array.findIndex(item => {
          return item.id === creative.id
        })
        if (index < 0) array.push(creative)
      })
      if (this.currentCreativeSelection === 'creative') this.form.creatives = array
      else if (this.currentCreativeSelection === 'square_creative') this.form.square_creatives = array
      this.currentCreativeSelection = ''
    },
    /**
     * Open destroy creative
     */
    openDestroyCreativeModal (creative, type) {
      this.selectedCreative = creative
      this.currentCreativeSelection = type
      this.$bvModal.show('destroy-creative-modal')
    },
    /**
     * Destroy creative
     */
    destroyCreative (creative) {
      if (this.currentCreativeSelection === 'creative') {
        const index = this.form.creatives.findIndex(item => {
          return item.id === creative.id
        })
        if (index >= 0) this.form.creatives.splice(index, 1)
      }
    },
    /**
     * Open select creative modal
     */
    openSelectCreativeModal (type) {
      this.currentCreativeSelection = type
      this.$bvModal.show('select-creative-modal')
    },
    saveDraft () {
      if (!this.selectedAdvertiser || this.step === 'campaign') return
      if (this.campaign && this.campaign.status !== 'DRAFT') return
      this.submit(true)
    },
    changeStep (step) {
      // if (step === 'adset') {
      //   this.getCreatives()
      // }
      this.step = step
    },
    setAutoSaveInterval () {
      this.autoSaveInterval = setInterval(() => {
        this.saveDraft()
      }, 30000)
    },
    addAllAvailableAmountToCampaign () {
      if (!this.selectedAdvertiser) return
      // If is creation, use full amount
      if (!this.isEditPage) {
        this.form.amount = (this.selectedAdvertiser.available_amount / 100)
      } else {
        // If is edit page, add the amount to what is in the form
        this.form.amount = this.form.amount + (this.selectedAdvertiser.available_amount / 100)
      }
    },
    async submit (isDraft = false) {
      // this.clearAutoSaveInterval()
      if (isDraft) this.isSavingDraft = true
      else this.loader = true
      try {
        this.form.countries = this.selectedCountries

        // Create copy of form that can be changed (object assign wont work for multi-layered objects)
        const form = JSON.parse(JSON.stringify(this.form))
        // Parse amounts
        form.amount = parseInt(form.amount * 100)
        form.cities = this.form.cities.map(item => {
          return item.code
        })
        form.states = this.form.states.map(state => {
          return state.code
        })
        if (form.creatives.length > 0) {
          form.creative_ids = form.creatives.map(item => {
            return item.id
          })
        }
        if (form.square_creatives.length > 0) {
          form.square_creative_ids = form.square_creatives.map(item => {
            return item.id
          })
        }

        let campaign = null
        form.is_draft = isDraft
        if (!this.campaign) {
          campaign = await CampaignsService.createCampaign(form)
        } else {
          campaign = await CampaignsService.updateCampaign(this.campaign.id, form)
        }
        if (!isDraft) {
          this.$router.push({ name: 'campaigns.show', params: { id: campaign.id } })
          const notification = {
            notification: {
              type: 'success',
              content: this.$t('campaign_in_creation_process')
            }
          }
          await this.createCampaign(form)
          NotificationService.createGlobalSuccessNotification(notification)
        } else {
          this.campaign = campaign
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      if (isDraft) this.isSavingDraft = false
      else this.loader = false
      // if (isDraft) this.setAutoSaveInterval()
    },
    /**
     * Select all states
     */
    selectAllStates () {
      const all = []
      this.stateOptions.forEach(item => {
        all.push({
          label: item.label,
          code: item.code
        })
      })
      this.form.states = all
    }
  },
  created () {
    this.getCampaign()
    if (this.isEditPage) {
      this.step = 'campaign'
    }
    // todo corrigir o autosave que esta com erro
    this.setAutoSaveInterval()
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .meta-campaign-navigation {
    position: absolute;
    top: 48px;
    left: -100px;
    z-index: 2;
    display: none;

    @include desktop {
      display: block;
    }
  }
}
</style>
