<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="highlighted-color1">
          <span>{{ isEditPage ? $t('edit') : $t('create') }} {{ $t('push_campaign') }}</span>
        </h1>
      </div>
      <div class="d-flex">
          <Button
          class="btn btn-color1 me-2"
          v-if="!isEditPage || (campaign && campaign.status === 'DRAFT')"
          :loading="isSavingDraft"
          @click="saveDraft"
          :disabled="!selectedAdvertiser"
        >
          {{ $t('save_draft') }}
        </Button>
        <Button
          class="btn btn-primary"
          v-b-modal.cancel-campaign-upsert-modal
        >
          {{ $t('cancel') }}
        </Button>
        <CancelCampaignUpsertModal
          :campaign="campaign"
          step="campaign"
        />
      </div>
    </div>
    <!-- CAMPAIGN FORM -->
    <div class="mt-3" v-if="!isEditPage || (isEditPage && campaign)">
      <div class="campaign-wrapper col-lg-8 mx-auto position-relative">
        <NativeCampaignNavigation
          :campaign="campaign"
          step="campaign"
          :innerStep="step"
          v-if="step !== 'init'"
        />
        <!-- INIT STEP -->
        <template v-if="step === 'init'">
          <div class="">
            <div class="card">
              <div class="card-body">
                <p>
                  {{ $t('easy_campaign_pixel_roads') }}
                </p>
                <p>
                  {{ $t('important_thoughts_for_campaign') }}
                </p>
                <ul>
                  <li>{{ $t('campaign_duration') }}</li>
                  <li>{{ $t('available_budget') }}*<br/>
                    <span class="font-size-xSmall">* {{ $t('daily_budget_calculation') }}</span>
                  </li>
                  <li>{{ $t('creatives_in_jpeg_or_png_format_1920x1080') }}</li>
                  <li>{{ $t('more_about_formats.0') }} <a href="https://pixelroads.com.br/blog/transforme-suas-campanhas-dicas-para-criar-banners-que-convertem/" target="_blank" class="link-color3">{{ $t('more_about_formats.1') }}</a>;</li>
                  <li>{{ $t('suggest_pixel_pre_installed') }}</li>
                </ul>
                <div class="mt-4 text-center">
                  <Button
                    class="btn btn-primary"
                    @click="changeStep('campaign')"
                  >
                    {{ $t('start2') }}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </template> <!-- END INIT STEP -->
        <!-- HEADING -->
        <section class="my-4" v-if="step !== 'init'">
          <div class="text-center">
            <p>
              {{ stepDescription }}
              <span
                v-b-tooltip.hover
                :title="tooltips.pixels"
                v-if="step === 'tracking'"
              >
                <i class="fa fa-info-circle font-size-regular"></i>
              </span>
            </p>
            <div class="mb-3 text-start">
              <a href="#" class="link-color2" @click.prevent="changeStep(previousStep)" v-if="previousStep"><i class="fa fa-angle-left me-2"></i>{{ $t('back') }}</a>
            </div>
          </div>
        </section> <!-- END HEADING -->
        <!-- CAMPAIGN STEP -->
        <template v-if="step === 'campaign'">
          <section class="">
            <div class="mb-5">
              <div class="card">
                <div class="card-body">
                  <form @submit.prevent="" class="default-form">
                    <!-- GENERAL DATA HEADER -->
                    <div class="mb-2">
                      <div class="d-flex align-items-center justify-content-between">
                        <div>
                          <h3 class="highlighted-color1 font-size-medium">
                            <span>{{ $t('general_campaign_data') }}</span>
                          </h3>
                        </div>
                      </div>
                    </div> <!-- END GENERAL DATA HEADER -->
                    <!-- ADVERTISER -->
                    <div class="form-group mb-3">
                      <label class="form-label fw-bold">
                        {{ $t('advertiser') }}*
                        <a
                          href="#"
                          @click.prevent=""
                          v-b-modal.upsert-advertiser-modal
                          v-if="!campaign"
                        >
                          {{ $t('new_advertiser2') }}
                        </a>
                      </label>
                      <div v-if="advertisersLoader">
                        <Loader
                          :size="'small'"
                          :align="'start'"
                        />
                      </div>
                      <template v-else>
                        <div class="custom-select">
                          <select
                            class="form-control"
                            v-model.trim="form.advertiser_id"
                            :disabled="campaign !== null && campaign.status !== 'DRAFT'"
                          >
                            <option disabled="true" selected="true">{{ $t('select') }}</option>
                            <template v-if="advertisers.length > 0">
                              <option v-for="(lAdvertiser, index) in advertisers" :key="index" :value="lAdvertiser.id">{{ lAdvertiser.name }}</option>
                            </template>
                          </select>
                        </div>
                        <p
                          class="font-size-xSmall fw-bold mt-2 error-message-card"
                          v-if="selectedAdvertiser && selectedAdvertiser.status === 'ERROR'"
                        >
                          {{ $t('advertiser_creation_issue_access_page_try_again', {name:selectedAdvertiser.name}) }}
                        </p>
                      </template>
                    </div> <!-- END ADVERTISER -->
                    <!-- NAME -->
                    <div class="form-group mb-3">
                      <label class="form-label fw-bold">{{ $t('campaign_name') }}*</label>
                      <input type="text" class="form-control" :placeholder="$t('campaign_name')" v-model.trim="form.name" />
                    </div> <!-- END NAME -->
                    <!-- DATES -->
                    <div class="form-group mb-3">
                      <label class="form-label fw-bold">{{ $t('duration') }}*</label>
                      <div class="d-flex">
                        <!-- START DATE -->
                        <div class="col-lg-3">
                          <DateTimeInput
                            v-model.trim="form.start_date"
                            format="dd/MM/yyyy"
                            type="date"
                            theme="basic"
                            class="mx-2"
                            :placeholder="$t('start')"
                            :readonly="campaign && campaign.has_started"
                          />
                        </div> <!-- END START DATE -->
                        <!-- END DATE -->
                        <div class="col-lg-3">
                          <DateTimeInput
                            v-model.trim="form.end_date"
                            format="dd/MM/yyyy"
                            type="date"
                            theme="basic"
                            class="mx-2"
                            :placeholder="$t('end')"
                          />
                        </div> <!-- END END DATE -->
                      </div>
                      <p
                        class="font-size-xSmall fw-bold mt-2 error-message-card"
                        v-if="form.start_date && !isCampaignStartDateValid"
                      >
                        {{ $t('start_date_not_before_today') }}
                      </p>
                      <p
                        class="font-size-xSmall fw-bold mt-2 error-message-card"
                        v-if="form.end_date && !isCampaignEndDateValid"
                      >
                        {{ $t('end_date_validation') }}
                      </p>
                      <p
                        class="font-size-xSmall fw-bold mt-2 error-message-card"
                        v-if="form.start_date && form.end_date && isCampaignEndDateBeforeStartDate"
                      >
                        {{ $t('end_date_not_before_start_date') }}
                      </p>
                      <p
                        class="font-size-xSmall fw-bold mt-2 alert-message-card"
                        v-if="form.start_date && form.end_date && isCampaignPeriodBelowRecommended"
                      >
                        {{ $t('campaign_minimum_duration_10_days') }}
                      </p>
                    </div> <!-- END DATES -->
                    <!-- GENERAL DATA HEADER -->
                    <div class="mb-2 mt-4">
                      <div class="d-flex align-items-center justify-content-between">
                        <div>
                          <h3 class="highlighted-color1 font-size-medium">
                            <span>{{ $t('segmentation') }}</span>
                          </h3>
                        </div>
                      </div>
                    </div> <!-- END GENERAL DATA HEADER -->
                    <div class="">
                      <!-- COUNTRIES -->
                      <div class="form-group mb-3 col-12 col-lg-12 me-2">
                        <div class="d-flex align-items-center mb-2">
                          <label class="form-label fw-bold mb-0">
                            {{ $t('countries') }}
                            <span v-b-tooltip.hover :title="tooltips.locations"><i class="fa fa-info-circle font-size-regular"></i></span>
                          </label>
                        </div>
                        <template v-if="countriesLoader">
                          <Loader
                            :size="'small'"
                            :align="'start'"
                          />
                        </template>
                        <template v-else>
                          <Multiselect
                            v-model="selectedCountries"
                            :placeholder="$t('search2')"
                            label="label"
                            track-by="code"
                            :options="countryOptions"
                            :multiple="true"
                          />
                        </template>
                      </div> <!-- END COUNTRIES -->
                      <div class="d-flex flex-wrap flex-lg-nowrap">
                        <!-- STATES -->
                        <div class="form-group mb-3 col-12 col-lg-6 me-2">
                          <div class="d-flex align-items-center mb-2">
                            <label class="form-label fw-bold mb-0">
                              {{ $t('states') }}
                              <span v-b-tooltip.hover :title="tooltips.locations"><i class="fa fa-info-circle font-size-regular"></i></span>
                            </label>
                            <Button
                              class="btn-xs btn-primary py-1 ms-2"
                              @click="selectAllStates"
                              v-if="stateOptions.length > 0"
                            >
                              {{ $t('select_all') }}
                            </Button>
                          </div>
                          <template v-if="statesLoader">
                            <Loader
                              :size="'small'"
                              :align="'start'"
                            />
                          </template>
                          <template v-else>
                            <Multiselect
                              v-model="form.states"
                              :placeholder="$t('search2')"
                              label="label"
                              track-by="code"
                              :options="stateOptions"
                              :multiple="true"
                              :disabled="selectedCountries.length == 0"
                            />
                            <div
                              class="mt-2 alert-message-card text-color2 font-size-small px-3 py-2"
                              v-if="selectedCountries.length == 0"
                            >
                              <p
                                class="mb-0"
                              >
                                {{ $t('select_at_least_one_country_for_state_selection') }}
                              </p>
                            </div>
                          </template>
                        </div> <!-- END STATES -->
                        <!-- CITIES -->
                        <div class="form-group mb-3 col-12 col-lg-6">
                          <label class="form-label fw-bold">
                            {{ $t('cities') }}
                            <span v-b-tooltip.hover :title="tooltips.locations"><i class="fa fa-info-circle font-size-regular"></i></span>
                          </label>
                          <template v-if="citiesLoader">
                            <Loader
                              :size="'small'"
                              :align="'start'"
                            />
                          </template>
                          <template v-else>
                            <Multiselect
                              v-model="form.cities"
                              :placeholder="$t('search2')"
                              label="label"
                              track-by="code"
                              :options="citiesOptions"
                              :multiple="true"
                              :disabled="selectedCountries.length == 0"
                            />
                            <div
                              class="mt-2 alert-message-card text-color2 font-size-small px-3 py-2"
                              v-if="selectedCountries.length == 0"
                            >
                              <p
                                class="mb-0"
                              >
                                {{ $t('select_at_least_one_country_for_city_selection') }}
                              </p>
                            </div>
                          </template>
                        </div> <!-- END CITIES -->
                      </div>
                      <div v-if="showAlertOnCitiesAndStatesSelection && (!countriesLoader && !citiesLoader && !statesLoader)">
                        <p class="fw-bold alert-message-card text-color2 font-size-xSmall mt-2" v-html="showAlertOnCitiesAndStatesSelection">
                        </p>
                      </div>
                    </div>
                    <div class="d-flex">
                      <!-- BROWSER -->
                      <div class="form-group mb-3 col-lg-6 me-2">
                        <label class="form-label fw-bold w-100 d-block">{{ $t('devices') }}*</label>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :id="`browser-mobile`"
                            value="MOBILE"
                            v-model="form.browser"
                          >
                          <label class="form-check-label" :for="`browser-mobile`">{{ $t('mobile') }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :id="`browser-desktop`"
                            value="DESKTOP"
                            v-model="form.browser"
                          >
                          <label class="form-check-label" :for="`browser-desktop`">{{ $t('desktop') }}</label>
                        </div>
                      </div> <!-- END BROWSER -->
                    </div>
                    <!-- GENERAL DATA HEADER -->
                    <div class="my-2">
                      <div class="d-flex align-items-center justify-content-between">
                        <div>
                          <h3 class="highlighted-color1 font-size-medium">
                            <span>{{ $t('budget') }}</span>
                          </h3>
                        </div>
                      </div>
                    </div> <!-- END GENERAL DATA HEADER -->
                    <!-- AMOUNT -->
                    <div class="form-group mb-3">
                      <label class="form-label fw-bold">{{ $t('campaign_budget') }}*</label>
                      <Money
                        class="form-control mb-3"
                        v-model.trim="form.amount"
                        v-bind="money"
                      />
                      <template v-if="selectedAdvertiser">
                        <div class="d-flex align-items-center flex-wrap">
                          <div class="col-12 col-lg-7">
                            <p
                              class="font-size-xSmall fw-bold mt-2 error-message-card"
                              v-if="hasCampaignExceededAvailableAdvertiserAmount"
                            >
                              {{ $t('campaign_budget_cannot_exceed') }}
                            </p>
                            <p class="font-size-xSmall fw-bold">
                              {{ $t('available_balance') }}: {{ selectedAdvertiser.available_amount / 100 | formatMoney(selectedAdvertiser.currency) }}
                            </p>
                          </div>
                          <div class="col-12 col-lg-5 text-lg-end d-flex align-items-center justify-content-center justify-content-lg-end">
                            <Button
                              class="btn btn-primary btn-xs me-2"
                              @click="addAllAvailableAmountToCampaign"
                            >
                              {{ $t('use_all_balance') }}
                            </Button>
                            <CreateOrderButton
                              :advertiser="selectedAdvertiser"
                              btnClasses="btn-xs"
                              @order-created="getAdvertisers"
                            />
                          </div>
                        </div>
                      </template>
                      <p
                        class="font-size-xSmall fw-bold mt-2 error-message-card"
                        v-if="!checkIfDailyBudgetIsBiggerThanMinimumDailyBudget"
                      >
                        {{ $t('recommended_minimum_daily_budget', {value:minimumDailyBudget, date:this.$options.filters.formatDate(suggestedEndDate)}) }}
                      </p>
                      <p
                        class="font-size-xSmall fw-bold mt-2 error-message-card"
                        v-if="form.amount > 0 && form.amount < 1200"
                      >
                        {{ $t('minimum_push_campaign_value') }} {{ 1200 | formatMoney(selectedAdvertiser ? selectedAdvertiser.currency : null) }}.
                      </p>
                    </div><!-- END AMOUNT -->
                    <!-- CPC TARGET AMOUNT -->
                    <div class="form-group mb-3">
                      <label class="form-label fw-bold">
                        {{ $t('cpc_goal') }}*
                        <span v-b-tooltip.hover.html :title="tooltips.cpc_goal"><i class="fa fa-info-circle font-size-regular"></i></span>
                      </label>
                      <Money
                        class="form-control mb-3"
                        v-model.trim="form.cpc_target_amount"
                        v-bind="money"
                      />
                      <p class="font-size-xSmall fw-bold error-message-card" v-if="form.cpc_target_amount !== '' && form.cpc_target_amount < 1">
                        {{ $t('push_minimum_cpc_goal_value') }} {{ 1 | formatMoney(selectedAdvertiser ? selectedAdvertiser.currency : null) }}.
                      </p>
                    </div><!-- END CPC TARGET AMOUNT -->
                    <!-- ACTION -->
                    <div class="mt-4 text-center">
                      <Button
                        class="btn btn-primary"
                        @click="changeStep('creative')"
                        :disabled="!isCampaignStepComplete || !checkIfDailyBudgetIsBiggerThanMinimumDailyBudget"
                      >
                        {{ $t('next') }}
                      </Button>
                    </div> <!-- END ACTION -->
                  </form>
                </div>
              </div>
            </div>
          </section>
          <UpsertAdvertiserModal
            @advertiser-created="getAdvertisers"
          />
        </template> <!-- END CAMPAIGN STEP -->
        <!-- CREATIVE STEP -->
        <template v-if="step === 'creative'">
          <section class="">
            <div class="mb-5">
              <div class="card mb-5">
                <div class="card-body">
                  <!-- RECTANGULAR HEADER -->
                  <div class="mb-2">
                    <div class="d-flex align-items-center justify-content-between">
                      <div>
                        <h3 class="highlighted-color1 font-size-medium">
                          <span>{{ $t('creative') }} 1920x1080*</span>
                        </h3>
                      </div>
                    </div>
                  </div> <!-- END RECTANGULAR HEADER -->
                  <div class="d-flex flex-column flex-lg-row align-items-center justify-content-center mt-4">
                    <div class="col-lg-8">
                      <!-- CREATIVE -->
                      <div
                        class="d-flex creative-item flex-wrap align-items-center mb-3"
                        v-for="(creative, index) in form.creatives" :key="index"
                      >
                        <div class="col-lg-3">
                          <img :src="creative.file" alt="" style="max-width: 100px;" />
                        </div>
                        <div class="col-lg-8 text-start">
                          <p class="mb-0">{{ creative.name }}</p>
                          <p class="mb-0">{{ creative.format | formatCreativeFormat }} | {{ creative.layout }}</p>
                        </div>
                        <div class="col-lg-1">
                          <Button
                            class="btn-xs btn-circle btn-danger font-size-small p-0"
                            @click="openDestroyCreativeModal(creative, 'creative')"
                            v-b-tooltip.hover :title="$t('remove_creative')"
                          >
                            <i class="fa fa-trash"></i>
                          </Button>
                        </div>
                      </div> <!-- END CREATIVE -->
                    </div>
                  </div>
                  <div class="text-center mt-3">
                    <Button
                      class="btn btn-primary mb-3 mb-lg-0"
                      @click="openSelectCreativeModal('creative')"
                    >
                      {{ $t('select_creatives') }}
                    </Button>
                  </div>
                </div>
              </div>

              <!-- ACTIONS -->
              <div class="text-center mt-3">
                <Button
                  class="btn btn-primary"
                  @click="changeStep('tracking')"
                  :disabled="!isCreativeStepComplete"
                >
                  {{ $t('next') }}
                </Button>
              </div> <!-- END ACTION -->
            </div>
          </section>
          <SelectCreativeModal
            :advertiserId="form.advertiser_id"
            format="PUSH"
            :layout="currentCreativeSelectionLayout"
            @creative-selected="handleSelectedCreatives"
          />
          <DestroyCreativeModal
            :creative="selectedCreative"
            @creative-destroyed="destroyCreative"
          />
        </template> <!-- END CREATIVE STEP -->
        <!-- TRACKING STEP -->
        <template v-if="step === 'tracking'">
          <section class="">
            <div class="mb-5">
              <div class="mt-4 text-center">
                <template v-if="form.pixels.length > 0">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>{{ $t('pixel_name') }}</th>
                        <th>{{ $t('rule_name') }}</th>
                        <th>{{ $t('objective') }}</th>
                        <th>{{ $t('event_name') }}</th>
                        <th width="100">{{ $t('actions') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="text-nowrap" v-for="(rule, index) in form.pixels" :key="index">
                        <td>{{ rule.pixel.name }}</td>
                        <td>{{ rule.name }}</td>
                        <td>{{ rule.objective | formatObjective }}</td>
                        <td>{{ rule.event_name }}</td>
                        <td>
                          <Button
                            class="btn-danger btn-xs font-size-small btn-circle p-0 mb-2"
                            @click.prevent="openDestroyPixelRuleModal(rule)"
                            v-b-tooltip.hover :title="$t('remove_rule')"
                          >
                            <i class="fa fa-trash"></i>
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
              </div>
              <DestroyPixelRuleModal
                :rule="selectedPixelRule"
                @pixel-rule-destroyed="destroyPixelRule"
              />
              <!-- ACTION -->
              <div class="text-center">
                <div>
                  <router-link
                    :to="{ name: 'advertisers.show', params: { id: form.advertiser_id }, query: { tab: 'pixels' } }"
                    class="btn btn-primary mb-4 me-2 btn-sm"
                    target="_blank"
                  >
                    {{ $t('add_new_pixel') }}
                  </router-link>
                  <Button
                    class="btn btn-primary mb-4 btn-sm"
                    v-b-modal.select-pixel-rule-modal
                  >
                    {{ $t('add_existing_pixel') }}
                  </Button>
                  <SelectPixelRuleModal
                    :advertiserId="form.advertiser_id"
                    @pixel-rule-selected="handleSelectedPixelRule"
                  />
                </div>
                <div>
                  <!-- MESSAGES -->
                  <div
                    class="mb-2 alert-message-card text-color2 font-size-small px-3 py-2"
                    v-if="loader"
                  >
                    <p
                      class="mb-0"
                    >
                      {{ $t('this_action_may_take_a_few_minutes') }}
                    </p>
                  </div> <!-- END MESSAGES -->
                  <Button
                    class="btn btn-primary"
                    @click="clickSubmit"
                    :disabled="!isFormComplete || isSavingDraft"
                    :loading="loader"
                  >
                    {{ campaign ? $t('save_campaign') : $t('create_campaign') }}
                  </Button>
                </div>
              </div> <!-- END ACTION -->
            </div>
          </section>
        </template> <!-- END TRACKING STEP -->
      </div>
    </div> <!-- END CAMPAIGN FORM -->
  </div>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import { getMoneyFormat } from '@/utils'
import HelpersService from '@/modules/helpers/services/helpers-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import variables from '@/mixins/variables'

import Button from '@/components/common/Button/Button'
import CancelCampaignUpsertModal from '@/components/campaigns/CancelCampaignUpsertModal'
import CreateOrderButton from '@/components/orders/CreateOrderButton'
import DateTimeInput from '@/components/common/Input/DateTimeInput'
import DestroyCreativeModal from '@/components/campaigns/UpsertForm/DestroyCreativeModal'
import DestroyPixelRuleModal from '@/components/campaigns/UpsertForm/DestroyPixelRuleModal'
import Loader from '@/components/common/Loader/Loader'
import { Money } from 'v-money'
import Multiselect from 'vue-multiselect'
import NativeCampaignNavigation from '@/components/campaigns/Native/NativeCampaignNavigation'
import SelectCreativeModal from '@/components/creatives/SelectCreativeModal'
import SelectPixelRuleModal from '@/components/pixels/SelectPixelRuleModal'
import UpsertAdvertiserModal from '@/components/advertisers/UpsertAdvertiserModal'

import { mapState } from 'vuex'
import moment from 'moment'

export default {
  mixins: [variables],
  props: ['baseCampaign'],
  components: {
    Button,
    CancelCampaignUpsertModal,
    CreateOrderButton,
    DateTimeInput,
    DestroyCreativeModal,
    DestroyPixelRuleModal,
    Loader,
    Money,
    Multiselect,
    NativeCampaignNavigation,
    SelectCreativeModal,
    SelectPixelRuleModal,
    UpsertAdvertiserModal
  },
  data () {
    return {
      autoSaveInterval: null,
      advertisers: [],
      advertisersLoader: false,
      campaign: null,
      cities: [],
      citiesLoader: false,
      countries: [],
      countriesLoader: false,
      currentCreativeSelection: '',
      form: {
        advertiser_id: '',
        name: '',
        start_date: '',
        end_date: '',
        amount: '',
        cpc_target_amount: '',
        browser: [],
        creatives: [],
        type: 'PUSH',
        pixels: [],
        states: [],
        cities: []
      },
      isSavingDraft: false,
      loader: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      selectedPixelRule: '',
      selectedCountries: [],
      selectedCreative: '',
      states: [],
      statesLoader: false,
      step: 'init',
      minimumDailyBudget: 10
    }
  },
  mounted () {
    if (this.isEditPage) {
      this.step = 'campaign'
      this.getCampaign()
    }
    if (this.user) this.getPageContent()
    // this.setAutoSaveInterval()
  },
  beforeDestroy () {
    // this.clearAutoSaveInterval()
  },
  watch: {
    user (newUser) {
      this.getPageContent()
    },
    /**
     * Watch for changes to countries
     */
    countries (newValue, oldValue) {
      // If is edit page, set selected countries
      if (this.isEditPage) this.setSelectedCountries()
    },
    /**
     * Watch for changes to selected countries
     */
    selectedCountries (newValue, oldValue) {
      this.getStates()
      this.getCities()
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    /**
     *  check if daily budget is bigger than minimum daily budget
     */
    checkIfDailyBudgetIsBiggerThanMinimumDailyBudget () {
      if (this.suggestedEndDate && this.form.amount > 0) {
        const startDate = moment(this.form.start_date)
        const endDate = moment(this.form.end_date)
        const campaignDays = endDate.diff(startDate, 'days') + 1
        const totalBudget = campaignDays * this.minimumDailyBudget

        return this.form.amount >= totalBudget
      }
      return true
    },
    /**
     * Get cities options based on the selected states of the line
     */
    citiesOptions () {
      /* const states = this.form.states.map(state => {
        return state.label
      }) */
      const cities = this.cities.reduce((filtered, item) => {
        /* if (states.includes(item.state_name)) {
          filtered.push({
            label: `${item.name} (${item.state_short_name})`,
            code: item.id
          })
        } */
        filtered.push({
          label: `${item.name} (${item.state_short_name} - ${item.country_short_name})`,
          code: item.id
        })
        return filtered
      }, [])
      return cities
    },
    /**
     * Get countries options
     */
    countryOptions () {
      return this.countries.map(item => {
        return {
          label: `${item.name} (${item.short_name})`,
          code: item.id
        }
      })
    },
    /**
     * Get current creative selection layout given current selected creative modal
     */
    currentCreativeSelectionLayout () {
      switch (this.currentCreativeSelection) {
        case 'creative':
          return '1920x1080'
        default:
          return ''
      }
    },
    /**
     * Check if campaign amount has exceeded what advertiser has available
     */
    hasCampaignExceededAvailableAdvertiserAmount () {
      if (!this.selectedAdvertiser) return true
      // If is new campaign, the only thing that matters is the full available amount
      const amount = this.form.amount * 100
      const available = this.selectedAdvertiser.available_amount
      if (!this.campaign) {
        return amount > available
      }

      // If is campaign that already exists, calculate the difference between new amount and old amount and compare that value to available amount
      const difference = amount - this.campaign.amount
      return difference > available
    },
    /**
     * Is campaign end date valid
     */
    isCampaignEndDateValid () {
      if (!this.form.end_date) return false
      const date = moment(this.form.end_date)
      // Check if is update and if value hasnt changed from original
      if (this.campaign && !this.isCampaignDraft) {
        const then = moment(this.campaign.end_date)
        if (date.isSame(then)) return true
      }
      const tomorrow = moment().startOf('day').add(1, 'day')
      return date.isSameOrAfter(tomorrow)
    },
    /**
     * Is campaign status draft
     */
    isCampaignDraft () {
      return this.campaign.status === 'DRAFT'
    },
    /**
     * Checks if campaign end date is before start date
     */
    isCampaignEndDateBeforeStartDate () {
      if (!this.form.end_date || !this.form.start_date) return true
      const startDate = moment(this.form.start_date)
      const endDate = moment(this.form.end_date)

      return endDate.isSameOrBefore(startDate)
    },
    /**
     * Check if campaign period is below recommended
     */
    isCampaignPeriodBelowRecommended () {
      if (!this.form.start_date || !this.form.end_date) return true
      const start = moment(this.form.start_date)
      const end = moment(this.form.end_date)
      return end.diff(start, 'days') < 10
    },
    /**
     * Is campaign start date valid
     */
    isCampaignStartDateValid () {
      if (!this.form.start_date) return false
      const date = moment(this.form.start_date)
      // Check if is update of started campaign and if value hasnt changed from original
      if (this.campaign && !this.isCampaignDraft) {
        const then = moment(this.campaign.start_date)
        if (date.isSame(then)) return true
      }
      const today = moment().endOf('day')
      return date.isAfter(today)
    },
    /**
     * Check if campaign is complete
     */
    isCampaignStepComplete () {
      if (
        !this.form.advertiser_id ||
        !this.form.name ||
        !this.form.start_date ||
        !this.form.end_date ||
        parseFloat(this.form.amount) < 1200 ||
        this.hasCampaignExceededAvailableAdvertiserAmount ||
        parseFloat(this.form.cpc_target_amount) < 1 ||
        !this.isCampaignStartDateValid ||
        !this.isCampaignEndDateValid ||
        this.isCampaignEndDateBeforeStartDate ||
        !this.selectedAdvertiser ||
        this.selectedAdvertiser.status === 'ERROR' ||
        this.form.browser.length === 0
      ) {
        return false
      }

      return true
    },
    /**
     * Check if creative step is complete
     */
    isCreativeStepComplete () {
      return (
        this.form.creatives.length > 0
      )
    },
    /**
     * Check if is edit page
     */
    isEditPage () {
      return this.$route.name === 'campaigns.edit'
    },
    /**
     * Is form complete
     */
    isFormComplete () {
      return (
        this.isCampaignStepComplete &&
        this.isCreativeStepComplete
      )
    },
    /**
     * Get previous step
     */
    previousStep () {
      switch (this.step) {
        case 'campaign':
          return null
        case 'creative':
          return 'campaign'
        case 'tracking':
          return 'creative'
        default:
          return null
      }
    },
    /**
     * Router name
     */
    routeName () {
      return this.$route.name
    },
    /**
     * Selected advertiser
     */
    selectedAdvertiser () {
      if (!this.form.advertiser_id) return null

      return this.advertisers.find(item => {
        this.money = getMoneyFormat(item.currency)

        return item.id === this.form.advertiser_id
      })
    },
    /**
     * show alert on cities and states selection
     */
    showAlertOnCitiesAndStatesSelection () {
      const selectedStates = this.form.states.map((state) => {
        const stateData = this.states.find((s) => s.id === state.code)
        return stateData?.name || ''
      })

      const selectedCities = this.form.cities.map((city) => {
        const cityData = this.cities.find((c) => c.id === city.code)
        return cityData ? { name: cityData.name, state_name: cityData.state_name } : null
      }).filter(city => city !== null)

      if (selectedStates.length && selectedCities.length) {
        // Check if the cities are within the selected state
        const citiesOutOfState = selectedCities.filter((city) =>
          !selectedStates.includes(city.state_name)
        )

        if (citiesOutOfState.length) {
          return this.$t('region_selection_states_and_cities', {
            states: selectedStates.join(', '),
            cities: [...new Set(citiesOutOfState.map((city) => city.state_name))].join(', ')
          })
        } else {
          return this.$t('region_selection_city_inside_state')
        }
      }

      if (selectedStates.length > 0) {
        return this.$t('region_selection_only_states')
      }

      if (selectedCities.length > 0) {
        return this.$t('region_selection_only_cities')
      }
      return false
    },
    /**
     * State options for select 2 field
     */
    stateOptions () {
      return this.states.map(state => {
        return {
          label: `${state.name} (${state.country_short_name})`,
          code: state.id
        }
      })
    },
    /**
     * Step description
     */
    stepDescription () {
      switch (this.step) {
        case 'campaign':
          return this.$t('create_basic_campaign_settings')
        case 'creative':
          return this.$t('configure_campaign_creatives')
        case 'tracking':
          return this.$t('configure_tracking_pixels')
        default:
          return ''
      }
    },
    /**
     * calculate campaign end date, minimum value 10 reais per day
     */
    suggestedEndDate () {
      if (!this.form.start_date || !this.form.end_date) return null

      const startDate = moment(this.form.start_date)

      const suggestedEndDate = startDate.clone().add(Math.floor(this.form.amount / this.minimumDailyBudget), 'days').subtract(1, 'day')
      return suggestedEndDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    }
  },
  methods: {
    /**
     * Add all selected advertiser available amount to campaign
     */
    addAllAvailableAmountToCampaign () {
      if (!this.selectedAdvertiser) return
      // If is creation, use full amount
      if (!this.isEditPage) {
        this.form.amount = (this.selectedAdvertiser.available_amount / 100)
      } else {
        // If is edit page, add the amount to what is in the form
        this.form.amount = this.form.amount + (this.selectedAdvertiser.available_amount / 100)
      }
    },
    /**
     * Change step of form
     */
    changeStep (step) {
      if (step === 'creative' || step === 'tracking') this.saveDraft()
      this.step = step
    },
    /**
     * Clear auto save interval
     */
    clearAutoSaveInterval () {
      // this.isAutoSaveEnabled = false
      clearInterval(this.autoSaveInterval)
    },
    /**
     * On click submit button
     */
    clickSubmit () {
      this.submit(false)
    },
    /**
     * Destroy creative
     */
    destroyCreative (creative) {
      if (this.currentCreativeSelection === 'creative') {
        const index = this.form.creatives.findIndex(item => {
          return item.id === creative.id
        })
        if (index >= 0) this.form.creatives.splice(index, 1)
      }
    },
    /**
     * Remove pixel rule from rules array
     */
    destroyPixelRule (rule) {
      const index = this.form.pixels.findIndex(item => {
        return item.id === rule.id
      })
      this.form.pixels.splice(index, 1)
    },
    /**
     * Get advertisers
     */
    async getAdvertisers () {
      this.advertisersLoader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          companyId: this.user.company.id,
          itemsPerPage: 100
        })
        this.advertisers = advertisers.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.advertisersLoader = false
    },
    /**
     * Get advertiser
     */
    async getCampaign () {
      if (!this.baseCampaign) return
      this.campaign = this.baseCampaign
      this.setCampaign(this.baseCampaign)
    },
    /**
     * Get cities
     */
    async getCities () {
      if (this.selectedCountries.length === 0) {
        this.cities = []
        return
      }
      this.citiesLoader = true
      try {
        const cities = await HelpersService.getCities({
          countryId: this.selectedCountries.map(item => {
            return item.code
          })
        })
        this.cities = cities
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.citiesLoader = false
    },
    /**
     * Get countries
     */
    async getCountries () {
      this.countriesLoader = true
      try {
        const countries = await HelpersService.getCountries()
        this.countries = countries
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.countriesLoader = false
    },

    /**
     * Get necessary page content
     */
    getPageContent () {
      this.getAdvertisers()
      this.getCountries()
      // this.getStates()
      // this.getCities()
    },
    /**
     * Get states
     */
    async getStates () {
      if (this.selectedCountries.length === 0) {
        this.states = []
        return
      }
      this.statesLoader = true
      try {
        const states = await HelpersService.getStates({
          country_id: this.selectedCountries.map(item => {
            return item.code
          })
        })
        this.states = states
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.statesLoader = false
    },
    /**
     * Handle selected creatives
     */
    handleSelectedCreatives (creatives) {
      let array = []
      if (this.currentCreativeSelection === 'creative') array = this.form.creatives
      creatives.forEach(creative => {
        const index = array.findIndex(item => {
          return item.id === creative.id
        })
        if (index < 0) array.push(creative)
      })
      if (this.currentCreativeSelection === 'creative') this.form.creatives = array
      this.currentCreativeSelection = ''
    },
    /**
     * Handle selected pixel rule
     */
    handleSelectedPixelRule (rule) {
      const index = this.form.pixels.findIndex(item => {
        return item.id === rule.id
      })
      if (index >= 0) return
      this.form.pixels.push(rule)
    },
    /**
     * Open destroy creative
     */
    openDestroyCreativeModal (creative, type) {
      this.selectedCreative = creative
      this.currentCreativeSelection = type
      this.$bvModal.show('destroy-creative-modal')
    },
    /**
     * Open modal to destroy pixel rule
     */
    openDestroyPixelRuleModal (rule) {
      this.selectedPixelRule = rule
      this.$bvModal.show('destroy-pixel-rule-modal')
    },
    /**
     * Open select creative modal
     */
    openSelectCreativeModal (type) {
      this.currentCreativeSelection = type
      this.$bvModal.show('select-creative-modal')
    },
    /**
     * Save draft
     */
    saveDraft () {
      if (!this.selectedAdvertiser) return
      if (this.campaign && this.campaign.status !== 'DRAFT') return
      this.submit(true)
    },
    /**
     * Select all states
     */
    selectAllStates () {
      const all = []
      this.stateOptions.forEach(item => {
        all.push({
          label: item.label,
          code: item.code
        })
      })
      this.form.states = all
    },
    /**
     * Create auto save interval
     */
    setAutoSaveInterval () {
      // this.isAutoSaveEnabled = true
      this.autoSaveInterval = setInterval(() => {
        this.saveDraft()
      }, 30000)
    },
    /**
     * Set campaign
     */
    setCampaign (campaign) {
      this.form.advertiser_id = campaign.advertiser.id
      this.form.name = campaign.name
      this.form.start_date = campaign.start_date
      this.form.end_date = campaign.end_date
      this.form.amount = campaign.amount / 100
      this.form.pixels = campaign.pixels.map(item => {
        return {
          id: item.id,
          name: item.name,
          objective: item.objective,
          event_name: item.event_name,
          pixel: item.pixel
        }
      })
      this.form.cpm_target_amount = campaign.cpm_target_amount / 100
      this.form.cpc_target_amount = campaign.cpc_target_amount / 100
      this.form.browser = campaign.browser
      campaign.creatives.creatives.forEach(creative => {
        this.form.creatives.push(creative)
      })
      this.form.states = campaign.states.map(s => {
        return {
          label: s.name,
          code: s.id
        }
      })
      this.form.cities = campaign.cities.map(c => {
        return {
          label: `${c.name} (${c.state_short_name})`,
          code: c.id
        }
      })
      this.setSelectedCountries()
    },
    /**
     * Set selected countries
     */
    setSelectedCountries () {
      if (!this.campaign) return
      const countries = []
      this.campaign.states.forEach(item => {
        if (!countries.includes(item.country_short_name)) countries.push(item.country_short_name)
      })
      this.campaign.cities.forEach(item => {
        if (!countries.includes(item.country_short_name)) countries.push(item.country_short_name)
      })
      this.selectedCountries = this.countries.filter(item => {
        return countries.includes(item.short_name)
      }).map(item => {
        return {
          label: `${item.name} (${item.short_name})`,
          code: item.id
        }
      })
    },
    /**
     * Submit
     */
    async submit (isDraft = false) {
      // this.clearAutoSaveInterval()
      if (isDraft) this.isSavingDraft = true
      else this.loader = true
      try {
        // Create copy of form that can be changed (object assign wont work for multi-layered objects)
        const form = JSON.parse(JSON.stringify(this.form))
        // Parse amounts
        form.amount = parseInt(form.amount * 100)
        form.cpc_target_amount = parseInt(form.cpc_target_amount * 100)
        form.cities = this.form.cities.map(item => {
          return item.code
        })
        form.states = this.form.states.map(state => {
          return state.code
        })
        if (form.creatives.length > 0) {
          form.creative_ids = form.creatives.map(item => {
            return item.id
          })
        }
        form.pixels = this.form.pixels.map(item => {
          return item.id
        })

        let campaign = null
        form.is_draft = isDraft
        if (!this.campaign) {
          campaign = await CampaignsService.createCampaign(form)
        } else {
          campaign = await CampaignsService.updateCampaign(this.campaign.id, form)
        }
        if (!isDraft) {
          this.$router.push({ name: 'campaigns.show', params: { id: campaign.id } })
          const notification = {
            notification: {
              type: 'success',
              content: this.$t('campaign_in_creation_process')
            }
          }
          NotificationService.createGlobalSuccessNotification(notification)
        } else {
          this.campaign = campaign
        }
      } catch (error) {
        // console.log(error)
        error.handleGlobally && error.handleGlobally()
      }
      if (isDraft) this.isSavingDraft = false
      else this.loader = false
      // Reenable autosave
      // if (isDraft) this.setAutoSaveInterval()
    }
  }
}
</script>

<style lang="css" src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
  ::v-deep {
    .native-campaign-navigation {
      position: absolute;
      top: 48px;
      left: -100px;
      z-index: 2;
      display: none;

      @include desktop {
        display: block;
      }
    }
  }
</style>
